import React, { useState } from "react";
import "./App.css";
// ant-d css
import "bootstrap/dist/css/bootstrap.min.css";
// import NotFound from './App/main-pages/NotFound';
import CopyrightFooter from "./App/common-components/copyright-footer/CopyrightFooter";
import Footer from "./App/common-components/footer/Footer";
import Header from "./App/common-components/header/Header";
import TopHeader from "./App/common-components/top-header/TopHeader";
import Mainpage from "./App/main-pages/MainPage";
// import Menue from "./App/main-pages/Menue";
// import {Helmet} from "react-helmet";

import {
  BrowserRouter as Router,
  Navigate, Route, Routes
} from "react-router-dom";
import ContactUs from "./App/main-pages/ContactUs";
import FaqPage from "./App/main-pages/FaqPage";
import OrderPage from "./App/main-pages/OrderPage";
import AboutUs from "./App/components/AboutUs/AboutUs";

function App() {

 let pathname = window.location.pathname.toString()
 pathname =pathname.replace('/','')
 const [select,setSelect] = useState(pathname===''?'home':pathname)
 console.log(pathname)

  return (
    <>
      <Router>
        <div className="App">
            {/* <Helmet>
                <meta charSet="utf-8" />
                <title>Burgers | Shakes | Waffles</title>
                <meta name="description" content="Freshly cooked premium smashed burgers and chicken burgers. Available for collection and delivery." />
                <link rel="canonical" href="https://woffleonline.com/" />
            </Helmet> */}
          <div
            class={(pathname === 'home' || pathname === 'contactus')?"sticky  top-0 left-0 ":''}
             style={(pathname === 'home' || pathname === 'contactus')?{ zIndex:'99', position: "fixed", width: "100%" }:{zIndex:'99', position: "absolute", width: "100%"}}
            //  style={{ zIndex:'99', position: "absolute", width: "100%" }}
          >
            <TopHeader />
            <Header select={select} setSelect={setSelect} />
          </div>

          <div style={{ position: "absolute", width: "100%" }}>
            <Routes>
            
              <Route path="" element={<Mainpage />} />
              {/* <Route path="/menu" element={<Menue />} /> */}
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/order" element={<OrderPage />} />
              <Route path="/faq" element={<FaqPage />} />
              <Route path="/contactus" element={<ContactUs />} />
               {/* <Route path="/*" element={ <Navigate to="/home" />} />  */}
               <Route path="*" element={ <Navigate to="" />} /> 
             
            </Routes>
            <Footer />
            <CopyrightFooter />
          </div>

        </div>
      </Router>
    </>
  );
}

export default App;
