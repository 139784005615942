import React from "react";
import { Call_PhoneNumber_Var, Facebook_Var, Instagram_Var, PhoneNumber_Var } from "../../../services/Data";

function Footer() {
  return (
    <>
      <footer class="bg-[#4D535F] pt-1 pb-1 xl:pt-8">
        {/* footer logo div */}
        <div class="relative flex    items-center mb-10 mt-4">
          <div class=" ml-2 flex-grow border-t border-slate-500 border-gray-200"></div>
          <span class="flex-shrink mx-4 text-gray-400">
            <img
              class="h-16 w-auto text-center"
              src="/images/logo/WoffleLogoWithoutGlow.png"
              alt="loading...."
            />
          </span>
          <div class=" mr-2 flex-grow border-t border-slate-500 border-gray-200"></div>
        </div>

        <div className="mb-10 -mt-6">
          <div class="text-center uppercase md:text-3xl text-xl text-white    font-bold">
            be part of the woffle family!
          </div>
          <div class="text-center uppercase md:text-base    text-xs text-white font-bold">
            follow us on our social media platforms
          </div>
          <div class="flex items-center justify-center my-3">
            {/* <div>
              <a
                href={Facebook_Var}
                target="_blank"
                className="text-white mt-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 mr-2 fill-current text-yellow-500 hover:text-yellow-400"
                  viewBox="0 0 333333 333333"
                  shape-rendering="geometricPrecision"
                  text-rendering="geometricPrecision"
                  image-rendering="optimizeQuality"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                >
                  <path
                    d="M197917 62502h52080V0h-52080c-40201 0-72909 32709-72909 72909v31250H83337v62507h41659v166667h62506V166666h52080l10415-62506h-62496V72910c0-5648 4768-10415 10415-10415v6z"
                    fill="#f59e0b"
                  />
                </svg>
              </a>
            </div> */}
            <div>
              <a
                href={Instagram_Var}
                target="_blank"
                className="text-white mt-1"
              >
                <svg
                  viewBox="0 0 512.00096 512.00096"
                  class="h-8 mr-2 fill-current text-yellow-500 hover:text-yellow-400"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m373.40625 0h-234.8125c-76.421875 0-138.59375 62.171875-138.59375 138.59375v234.816406c0 76.417969 62.171875 138.589844 138.59375 138.589844h234.816406c76.417969 0 138.589844-62.171875 138.589844-138.589844v-234.816406c0-76.421875-62.171875-138.59375-138.59375-138.59375zm108.578125 373.410156c0 59.867188-48.707031 108.574219-108.578125 108.574219h-234.8125c-59.871094 0-108.578125-48.707031-108.578125-108.574219v-234.816406c0-59.871094 48.707031-108.578125 108.578125-108.578125h234.816406c59.867188 0 108.574219 48.707031 108.574219 108.578125zm0 0" />
                  <path d="m256 116.003906c-77.195312 0-139.996094 62.800782-139.996094 139.996094s62.800782 139.996094 139.996094 139.996094 139.996094-62.800782 139.996094-139.996094-62.800782-139.996094-139.996094-139.996094zm0 249.976563c-60.640625 0-109.980469-49.335938-109.980469-109.980469 0-60.640625 49.339844-109.980469 109.980469-109.980469 60.644531 0 109.980469 49.339844 109.980469 109.980469 0 60.644531-49.335938 109.980469-109.980469 109.980469zm0 0" />
                  <path d="m399.34375 66.285156c-22.8125 0-41.367188 18.558594-41.367188 41.367188 0 22.8125 18.554688 41.371094 41.367188 41.371094s41.371094-18.558594 41.371094-41.371094-18.558594-41.367188-41.371094-41.367188zm0 52.71875c-6.257812 0-11.351562-5.09375-11.351562-11.351562 0-6.261719 5.09375-11.351563 11.351562-11.351563 6.261719 0 11.355469 5.089844 11.355469 11.351563 0 6.257812-5.09375 11.351562-11.355469 11.351562zm0 0" />
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div class="max-w-screen-lg xl:max-w-screen-xl mx-auto px-2  sm:px-6 md:px-8 text-gray-400 dark:text-gray-300">
          <ul class="text-lg font-light  flex flex-wrap justify-center">
            <li class="w-1/2 md:w-1/3 lg:w-1/3 sm:pl-9  text-left pr-1  -ml-8">
              <div class="">
                <p class="text-xs flex ">
                  <svg
                    id="Layer_1"
                    class="h-4 mr-2 fill-current text-yellow-500 hover:text-yellow-400"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 92.53 122.88"
                  >
                    <defs></defs>
                    <title>map-pin-point</title>
                    <path
                      class="cls-1"
                      d="M46.27,24.08A22.75,22.75,0,1,1,23.52,46.83,22.75,22.75,0,0,1,46.27,24.08Zm1.36,91.62A82.87,82.87,0,0,0,64,101.81,85.44,85.44,0,0,0,84.56,62.23c2.81-13.67,1.5-27-4.75-37.34a36.74,36.74,0,0,0-6.63-8.06C65.62,9.93,57,6.64,48.23,6.56,39.07,6.49,29.77,10,21.55,16.5a38.54,38.54,0,0,0-8.63,9.56C7.15,35.15,5.41,46.43,7.31,58.24c1.94,12,7.66,24.61,16.77,36A102.46,102.46,0,0,0,47.63,115.7Zm21.24-9.46a89.32,89.32,0,0,1-19.33,16,3.28,3.28,0,0,1-3.71.13,109.25,109.25,0,0,1-26.9-24c-9.8-12.31-16-26-18.1-39.1C-1.33,45.89.7,33,7.36,22.53a45.3,45.3,0,0,1,10.1-11.18C26.85,3.87,37.6-.09,48.29,0,58.6.09,68.79,3.92,77.6,12a43.1,43.1,0,0,1,7.82,9.52c7.15,11.8,8.71,26.83,5.57,42.05a92.2,92.2,0,0,1-22.12,42.7Z"
                    />
                  </svg>
                  31 County Road, Walton,
                </p>
                <p class="text-xs pt-1 -mt-4 ml-5">Liverpool, L4 3QA</p>
                <p class="text-xs flex mt-4">
                  <svg
                    id="Layer_3"
                    class="h-4 mr-2 fill-current text-yellow-500 hover:text-yellow-400"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 3"
                  >
                    <path d="m29.393 23.36c-.874-.733-6-3.979-6.852-3.83-.4.071-.706.412-1.525 1.389a11.687 11.687 0 0 1 -1.244 1.347 10.757 10.757 0 0 1 -2.374-.88 14.7 14.7 0 0 1 -6.784-6.786 10.757 10.757 0 0 1 -.88-2.374 11.687 11.687 0 0 1 1.347-1.244c.976-.819 1.318-1.123 1.389-1.525.149-.854-3.1-5.978-3.83-6.852-.306-.362-.584-.605-.94-.605-1.032 0-5.7 5.772-5.7 6.52 0 .061.1 6.07 7.689 13.791 7.721 7.589 13.73 7.689 13.791 7.689.748 0 6.52-4.668 6.52-5.7 0-.356-.243-.634-.607-.94z" />
                    <path d="m23 15h2a8.009 8.009 0 0 0 -8-8v2a6.006 6.006 0 0 1 6 6z" />
                    <path d="m28 15h2a13.015 13.015 0 0 0 -13-13v2a11.013 11.013 0 0 1 11 11z" />
                  </svg>
                  <a href={`tel:${Call_PhoneNumber_Var}`}  style={{textDecoration:'none'}} class="text‑inherit phoneNumberfooter text-xs ">{PhoneNumber_Var}</a>
                  {/* +1 718-904-4450 */}
                </p>
              </div>
            </li>
            <li class="w-1/2 md:w-1/3 lg:w-1/3 sm:pl-16  text-left ">
              <div class="">
                <h2 class="text-gray-500 dark:text-gray-200 text-sm uppercase mb-1">
                  Opening Hours
                </h2>
                <p class="text-xs">
                  Monday - Sunday 4 - 11pm
                  {/* <p class="text-xs pt-1">Friday - Sunday 4 - 11pm</p> */}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
}

export default Footer;
