import React from 'react'
import {Helmet} from "react-helmet";

export default function AboutUs() {
  return (
    <div className='' >
            <Helmet>
                <meta charSet="utf-8" />
                <title>About Us</title>
                <meta name="description" content="At Woffle we beleive in fresh food.Our burgers are made of the highest quality ingredients in house daily." />
                <link rel="canonical" href="https://woffleonline.com/aboutus" />
            </Helmet>
             {/* /************************************ contact Us - start ************************************/}
             <div  class="  bg-ContactUs-bg bg-cover py-16  bg-no-repeat bg-center py-14 text-gray-400 dark:bg-gray-800 flex 
       relative z-20 items-center overflow-hidden
       md:pt-16 pt-24    
       ">
  <div class="container pt-16 text-left  px-6  relative sm:py-16 ">
        <div class=" pt-24  ">
         
            <h1 class="font-bebas-neue 
            font-bold  uppercase 
             sm:text-6xl text-5xl xs:text-red-400
              sm:mb-16 mb-5 flex flex-col leading-none
                text-white  
                md:pl-16 pl-8
                font-Bebas
                shadow-neutral-300
                contactUsTitleDiv
              ">
                About Us
              
            </h1>
    



        </div>
     
    </div>
</div>
  {/* /************************************ contact Us - end ************************************/}
  <div class=" bg-mainpageburger-bg bg-cover bg-no-repeat bg-center py-14 text-gray-400 dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
        <div class="container pt-16 text-left  px-6  relative sm:py-16 ">
          <div class=" pt-8  ">
            <h1
              class="font-bebas-neue 
            font-bold  uppercase 
             sm:text-6xl text-4xl xs:text-red-400
              sm:mb-16 mb-5 flex flex-col leading-none
               text-[#FFDE59]
                md:pl-16 pl-8
                font-Bebas
                shadow-neutral-300
              "
              
            >
              About Us
           
            </h1>
            <p class="text-sm  md:pl-16 pl-8 -mt-8 pr-40 w-[600px] homePageBurgerDetails font-nunito text-[#FFFFFF] dark:text-white ">
              <br />
              <br />
              At Woffle we beleive in fresh food.Our burgers are made of the
              highest quality ingredients in house daily.The best burgers start 
              with the best beef.
We treat every part of a burger with ultimate levels of dedication and care. At woffle you are gurranteed the ultimate burger experience.
Everything at woffle is made fresh which is why everything tastes so great.Our mission is to provide everyone of our customers the ultimate burger & dessert experience.
Through the use of freshly sourced,the highest quality ingredients and attention towards every burger and dessert, we guarantee you will soon be part of the woffle family!
            </p>
          
          </div>
        </div>
      </div>
    </div>
  )
}
