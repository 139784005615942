import React from 'react'
import { Call_PhoneNumber_Var } from '../../../services/Data'

function TopHeader() {
  return (
    <>
    <div className="bg-[#4D535F] py-2 md:text-right sm:text-center md:pr-20 sm:pr-0  ">
    <div class="sm:container mx-auto ">
    <span class="text-gray-50 text-sm  ">To Order Call Now!</span>
    <a  href={`tel:${Call_PhoneNumber_Var}`} style={{textDecoration:'none'}} class="text‑inherit phoneNumber text-sm	text-yellow-400 font-bold pl-2 hover:text-yellow-400">{Call_PhoneNumber_Var}</a>
    {/* <span class="text‑inherit text-sm	text-yellow-400 font-bold "> +1 718-904-4450</span> */}
    </div>
    </div>
    </>
  )
}

export default TopHeader