import React ,{useRef,useEffect,forwardRef, useState} from "react";
import OrderRowItem from "../common-components/order-row/OrderRowItem";
import OrderPageView from "../components/order-page/OrderPageView";
import OurMenueNavBar from "../components/our-menue/OurMenueNavBar";
import MenueService from "../../services/MenueService.json";
function OrderPage() {
  const [scroll,setscroll] = useState(useRef(null))
  const [select,setSelect] = useState(MenueService[0].name)
  const [selectedItem,setSelectedItem] = useState(MenueService[0])
  const [postions,setPostions] = useState([])
 
  
  const myRef = useRef(null)
  const scrollToRef = (ref) => {window.scrollTo(0, ref.current.offsetTop) }
  const executeScroll = () => scrollToRef(myRef)

  const listenScrollEvent = async (e) => {
    console.log("window.scrollY",window.scrollY,window)
    // postions.map((item)=>{
    //   if(window.scrollY,window > item.)
    // })

  }

  
 
  useEffect(()=>{
    window.addEventListener('scroll', GettingPoistion)
  },[])

  const GettingPoistion = async ()=>{
   const ItemPositions =  await Promise.all(MenueService.map(item=>{
      var element = document.getElementById(item.name);
      var topPos = element.getBoundingClientRect().top + window.scrollY;
      // return {itemname:item.name,topPos:topPos}
      if(window.scrollY >= topPos){
        setSelect(item.name)
      }
    }))
    console.log(ItemPositions)
    setPostions(ItemPositions)
    
  }

  return (
  
    <>
     
      {/*********************************/}
      <OrderPageView/>
      
      <div class="grid xl:grid-cols-4  md:grid-col-2  sm:mb-8  ">
        <div class="col-span-3 ">
         
          {/*****************/}
          <div className="px-2 ">
          <div class="items-center flex flex-col my-auto   pt-10 sticky top-0 left-0 bg-white"  style={{zIndex:'9'}}   >
          {/* <ul class="sm:flex"> */}
          <ul class="flex pr-6 xl:overflow-x-hidden orderPageNavBar overflow-x-scroll sm:w-[600px] w-[450px] md:w-[700px]  lg:w-[900px]  xl:w-auto "  >
            {MenueService.map((item) => {
              return (
                <li class=" mx-2" onClick={()=>{
                  setSelect(item.name); 
                 setscroll(item.name);
                 console.log(myRef.current)
                
                 myRef.current = document.getElementById(item.name)
                 console.log('new',myRef.current)
                 executeScroll()
              
                }}>
                  <p class={` lg:text-base text-sm 
                  font-bold hover:text-yellow-400
                  pb-1
                    hover:underline hover:underline-offset-8 uppercase truncate
                    `+`${(select === item.name)?'underline underline-offset-8 text-yellow-400':''}` }>
                    {item.name}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
          {/******************/}
          {
            MenueService.map((item)=>{
              // console.log(item.name, scroll,"====>",(item.name === scroll),myRef)
              return(
                <div className="sm:px-8" id={item.name} ref={(item.name === scroll) ? myRef :null} >
                <OrderRowItem name={item.name} items={item.items}/>
                </div>
              )
            })
          }

         
           {/******************/}
          </div>
        </div>
        <div class=" text-center  OrderPageNumberBox pt-9 pr-16  sm:invisible   xl:visible" >
              {/********* left side *******/}
              <div className="text-center mt-9 px-3 "
               class="sticky  top-0 left-0 "
            //    style={{ zIndex: "9999", position: "fixed", width: "100%" }}
            flex flex-col mx-auto
              >
                <div>
                <div className="text-white bg-gray-900 py-2">
                    To order call now!
                </div>
                <div className="text-center py-3  mx-auto "
                 style={{
                  backgroundImage: "linear-gradient(to right,#FFDF58,#171E2A)	",
                }}
                >
                    <div className="text-center container   text-center">
                <p class="text-sm  relative mx-8 flex text-white  ">
                 
                  <svg
                    id="Layer_3"
                    class="h-5 mr-3 fill-current text-white  "
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 3"
                  >
                    <path d="m29.393 23.36c-.874-.733-6-3.979-6.852-3.83-.4.071-.706.412-1.525 1.389a11.687 11.687 0 0 1 -1.244 1.347 10.757 10.757 0 0 1 -2.374-.88 14.7 14.7 0 0 1 -6.784-6.786 10.757 10.757 0 0 1 -.88-2.374 11.687 11.687 0 0 1 1.347-1.244c.976-.819 1.318-1.123 1.389-1.525.149-.854-3.1-5.978-3.83-6.852-.306-.362-.584-.605-.94-.605-1.032 0-5.7 5.772-5.7 6.52 0 .061.1 6.07 7.689 13.791 7.721 7.589 13.73 7.689 13.791 7.689.748 0 6.52-4.668 6.52-5.7 0-.356-.243-.634-.607-.94z" />
                    <path d="m23 15h2a8.009 8.009 0 0 0 -8-8v2a6.006 6.006 0 0 1 6 6z" />
                    <path d="m28 15h2a13.015 13.015 0 0 0 -13-13v2a11.013 11.013 0 0 1 11 11z" />
                  </svg>
                  <a href="tel:01512455680"  style={{textDecoration:'none',color:'white'}} >0151 245 5680</a>
                </p>
             

                </div>
                </div>
                </div>
              </div>
              {/***************************/}
        </div>
      </div>
      {/*********************************/}
    </>
  );
}

export default OrderPage;
