import React from "react";
import {Helmet} from "react-helmet";

export default function OrderPageView() {
  return (
    <>
       <div  class="  bg-mainpagedesk-bg bg-cover py-16 bg-no-repeat bg-center py-20   ">
       <Helmet>
                <meta charSet="utf-8" />
                <title>Order Now</title>
                <meta name="description" content="Discover mouthwatering beef and chicken burgers at Woffleonline! Explore our diverse menu, featuring delectable options for every palate. Order online for a delightful dining experience, right at your doorstep. Taste the difference today!." />
                <link rel="canonical" href="https://woffleonline.com/order" />
            </Helmet>
    <div class="container pt-24 mx-auto px-6 flex relative sm:py-16 flex  justify-left   my-3">
        <div class="py-16 ">
         
            <h1 class="font-bebas-neue 
            font-bold  uppercase 
             sm:text-6xl text-4xl 
               mb-5  pl-10
                
                text-white  
                shadow-neutral-300
               
              ">
               
              
               
            </h1>

        </div>

    </div>
</div>
    </>
  );
}
