import React from "react";
import { Helmet } from "react-helmet";


function ContactUs() {

  return (
    <>
      <div className=" ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact Us</title>
          <meta name="description" content="Get in touch with Woffleonline - Your feedback matters! Whether you have questions, feedback, or want to collaborate, our team is here to assist you. Reach out to us today for the best in customer service and culinary excellence." />
          <link rel="canonical" href="https://woffleonline.com/contactus" />
        </Helmet>
        {/* /************************************ contact Us - start ************************************/}
        <div class="  bg-ContactUs-bg bg-cover py-16  bg-no-repeat bg-center py-14 text-gray-400 dark:bg-gray-800 flex 
       relative z-20 items-center overflow-hidden
       md:pt-16 pt-24    
       ">
          <div class="container pt-16 text-left  px-6  relative sm:py-16 ">
            <div class=" pt-24  ">

              <h1 class="font-bebas-neue 
            font-bold  uppercase 
             sm:text-6xl text-5xl xs:text-red-400
              sm:mb-16 mb-5 flex flex-col leading-none
                text-white  
                md:pl-16 pl-8
                font-Bebas
                shadow-neutral-300
                contactUsTitleDiv
              ">
                Contact Us

              </h1>




            </div>

          </div>
        </div>
        {/* /************************************ contact Us - end ************************************/}
        <div class="  bg-map-bg pt-[80vh] pb-20 bg-cover    bg-center bg-opacity-75 md:bg-opacity-50 bg-opacity-20 ">

          {/* <div style={{ height: '100vh', width: '100%', backgroundColor:'red' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: YOUR_GOOGLE_MAP_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={59.955413}
          lng={30.337844}
          text="My Marker"
        />
      </GoogleMapReact>
    </div> */}

          <div class="  ">
            {/* /*******************/}
            <div class="lg:flex contactUsContentDiv justify-around text-white mx-32 pt-12 lg-pl-0 sm:pl-20 px-4" style={{ backgroundColor: 'rgb(17, 24, 39,0.8)' }}>
              {/* /****** - 1 - ********/}
              <div className="" >

                <h1 className="
          uppercase text-left  text-yellow-400 font-bold  md:text-3xl  sm:text-2xl text-xl
          "> GET IN TOUCH</h1>
                {/* <p className="text-left text-white md:text-lg text-xs">In case of question, feel free to reach out to us</p> */}
              </div>
              {/* /****** - 2 - ********/}
              <div className=" sm:pt-0 -pt-20 " >
                <p class="text-xs flex justify-items-center lg:py-4 -py-12 text-left">
                  <svg
                    id="Layer_3"
                    class="h-8 mr-2 fill-current text-yellow-500 hover:text-yellow-400"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 3"
                  >
                    <path d="m29.393 23.36c-.874-.733-6-3.979-6.852-3.83-.4.071-.706.412-1.525 1.389a11.687 11.687 0 0 1 -1.244 1.347 10.757 10.757 0 0 1 -2.374-.88 14.7 14.7 0 0 1 -6.784-6.786 10.757 10.757 0 0 1 -.88-2.374 11.687 11.687 0 0 1 1.347-1.244c.976-.819 1.318-1.123 1.389-1.525.149-.854-3.1-5.978-3.83-6.852-.306-.362-.584-.605-.94-.605-1.032 0-5.7 5.772-5.7 6.52 0 .061.1 6.07 7.689 13.791 7.721 7.589 13.73 7.689 13.791 7.689.748 0 6.52-4.668 6.52-5.7 0-.356-.243-.634-.607-.94z" />
                    <path d="m23 15h2a8.009 8.009 0 0 0 -8-8v2a6.006 6.006 0 0 1 6 6z" />
                    <path d="m28 15h2a13.015 13.015 0 0 0 -13-13v2a11.013 11.013 0 0 1 11 11z" />
                  </svg>
                  <p className="pl-2">
                    <p className=" font-bold mb-1">Call Us</p>
                    <a href="tel:01512455680" style={{ textDecoration: 'none' }} class="-mt-3 text-xs text-gray-400 phoneNumberContactus  ">0151 245 5680</a>

                    {/* <p className="-mt-3 text-xs text-gray-400">
                    +1 718-904-4450
                  </p> */}
                  </p>
                </p>
              </div>
              {/* /****** - 3 - ********/}
              <div className="">
                <p class="text-xs flex lg:py-4 -py-12 text-left ">
                  <svg
                    id="Layer_1"
                    class="h-8 mr-2 fill-current text-yellow-500 hover:text-yellow-400"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 92.53 122.88"
                  >
                    <defs></defs>
                    <title>map-pin-point</title>
                    <path
                      class="cls-1"
                      d="M46.27,24.08A22.75,22.75,0,1,1,23.52,46.83,22.75,22.75,0,0,1,46.27,24.08Zm1.36,91.62A82.87,82.87,0,0,0,64,101.81,85.44,85.44,0,0,0,84.56,62.23c2.81-13.67,1.5-27-4.75-37.34a36.74,36.74,0,0,0-6.63-8.06C65.62,9.93,57,6.64,48.23,6.56,39.07,6.49,29.77,10,21.55,16.5a38.54,38.54,0,0,0-8.63,9.56C7.15,35.15,5.41,46.43,7.31,58.24c1.94,12,7.66,24.61,16.77,36A102.46,102.46,0,0,0,47.63,115.7Zm21.24-9.46a89.32,89.32,0,0,1-19.33,16,3.28,3.28,0,0,1-3.71.13,109.25,109.25,0,0,1-26.9-24c-9.8-12.31-16-26-18.1-39.1C-1.33,45.89.7,33,7.36,22.53a45.3,45.3,0,0,1,10.1-11.18C26.85,3.87,37.6-.09,48.29,0,58.6.09,68.79,3.92,77.6,12a43.1,43.1,0,0,1,7.82,9.52c7.15,11.8,8.71,26.83,5.57,42.05a92.2,92.2,0,0,1-22.12,42.7Z"
                    />
                  </svg>
                  <p className="pl-2">
                    <p className=" font-bold">Address</p>
                    <p className="-mt-3 text-xs text-gray-400">
                      31 County Road,<br /> Walton,Liverpool, L4 3QA
                    </p>
                  </p>
                </p>
              </div>
              {/* /****** - 4 - ********/}
              <div className="">
                <p class="text-xs flex lg:py-4 -py-12 text-left ">
                  <svg id="Layer_1" data-name="Layer 1"
                    class="h-6 mr-2 fill-current text-yellow-500 hover:text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 88.86"><title>email</title><path d="M7.05,0H115.83a7.07,7.07,0,0,1,7,7.05V81.81a7,7,0,0,1-1.22,4,2.78,2.78,0,0,1-.66,1,2.62,2.62,0,0,1-.66.46,7,7,0,0,1-4.51,1.65H7.05a7.07,7.07,0,0,1-7-7V7.05A7.07,7.07,0,0,1,7.05,0Zm-.3,78.84L43.53,40.62,6.75,9.54v69.3ZM49.07,45.39,9.77,83.45h103L75.22,45.39l-11,9.21h0a2.7,2.7,0,0,1-3.45,0L49.07,45.39Zm31.6-4.84,35.46,38.6V9.2L80.67,40.55ZM10.21,5.41,62.39,47.7,112.27,5.41Z" /></svg>
                  <p className="pl-2">
                    <p className=" font-bold">Email Us</p>
                    <p className="-mt-3 text-xs text-gray-400">
                      info@woffleonline.com
                    </p>
                  </p>
                </p>
              </div>
            </div>
            {/* /*******************/}
          </div>


        </div>

      </div>
    </>
  );
}

export default ContactUs;
