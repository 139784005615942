import React, { useState } from "react";

import {
  Link
} from "react-router-dom";
import { Instagram_Var } from "../../../services/Data";
function Header({select,setSelect}) {

//  let pathname = window.location.pathname.toString()
//  pathname =pathname.replace('/','')

  const [navBar,setNavBar] = useState(true)
  // const [select,setSelect] = useState(pathname)
  // const Array = ["home", "menu", "contactus"];

  const Array = [
    {name:"home",link:"home"},
    {name:"about us",link:"aboutus"},
    {name:"contact us",link:"contactus"},
  ]
  return (
    <>
      <div class="flex justify-center ">
        <div className={" md:flex w-4/5 bg-[#4D535F]  justify-between text-white md:h-24  my-auto  items-center rounded-b-xl  "+`${navBar ? ' h-16 ':'h-68'}`}>
         {/* / */}
          <div className=" flex flex-row ml-5  items-center ">
            <span onClick={()=>{setNavBar(!navBar)}} class="md:invisible"style={{position:"relative",right:0}}>
          <svg id="Layer_1" class="h-4 mr-2 fill-current text-yellow-500 hover:text-yellow-400" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 102.4"><defs><style></style></defs><title>list-view</title><path class="cls-1" d="M35.37,0h86a1.56,1.56,0,0,1,1.55,1.55V20.21a1.58,1.58,0,0,1-1.55,1.54h-86a1.56,1.56,0,0,1-1.54-1.54V1.55A1.54,1.54,0,0,1,35.37,0Zm0,80.65h86a1.56,1.56,0,0,1,1.55,1.54v18.67a1.58,1.58,0,0,1-1.55,1.54h-86a1.56,1.56,0,0,1-1.54-1.54V82.19a1.54,1.54,0,0,1,1.54-1.54Zm-33.82,0h16a1.56,1.56,0,0,1,1.55,1.54v18.67a1.58,1.58,0,0,1-1.55,1.54h-16A1.56,1.56,0,0,1,0,100.86V82.19a1.54,1.54,0,0,1,1.55-1.54ZM35.37,62.08h86a1.56,1.56,0,0,0,1.55-1.55V41.87a1.58,1.58,0,0,0-1.55-1.55h-86a1.57,1.57,0,0,0-1.54,1.55V60.53a1.55,1.55,0,0,0,1.54,1.55Zm-33.82,0h16a1.56,1.56,0,0,0,1.55-1.55V41.87a1.58,1.58,0,0,0-1.55-1.55h-16A1.57,1.57,0,0,0,0,41.87V60.53a1.55,1.55,0,0,0,1.55,1.55ZM1.55,0h16A1.56,1.56,0,0,1,19.1,1.55V20.21a1.58,1.58,0,0,1-1.55,1.54h-16A1.56,1.56,0,0,1,0,20.21V1.55A1.54,1.54,0,0,1,1.55,0Z"/></svg>
          </span>
          <a href={"/"} className="text-white no-underline w-[80%]   flex items-center justify-center">
            <img
            
              class="sm:h-16 h-12 md:ml-0  mx-auto"
              src="/images/logo/WoffleLogoWithoutGlow.png"
              alt="loading...."
            />
            </a>
          </div>
      {/* / */}
          {/* <div class={" md:mr-2	lg:mr-10 pt-5 md: "+navBar?`hidden md:block`:''}> */}
          <div class={" md:mr-2	lg:mr-10 md:pt-12 pt-20"}>
            <ul class={"md:flex md:mt-0 -mt-16 sm:-mt-16"+ `md:${navBar ? ' hidden':''}`} >
                    {/* /************** home ************/}
                  <Link to={'home'} className="text-white no-underline	" onClick={()=>{setNavBar(!navBar); setSelect('home')}}>
                  <li class=" lg:mx-6 sm:mx-1  mr-1">
                    <p class={` 
                 
                     lg:text-sm  md:text-xs text-xs  font-semibold hover:text-yellow-400 
                     hover:underline hover:underline-offset-8 uppercase`
                      +`${(select === 'home')?' text-yellow-400 underline underline-offset-8':''}`
                     }>
                      home
                    </p>
                  </li>
                  </Link>
                     {/* /************** menu ************/}
              <Link to={'order'} className="text-white no-underline	" onClick={()=>{ setNavBar(!navBar);setSelect('order')}}>
              <li class=" md:mx-3   mr-6 lg:mr-4">
                {/* <p class={" lg:text-base rounded px-4 py-1 -mt-1 bg-green-500 text-gray-900 hover:bg-yellow-400 text-gray-900 lg:text-sm  md:text-xs font-semibold uppercase"+`${(select === 'order')?' text-yellow-600 bg-yellow-700 ':''}`}> */}
                <p class={" lg:text-base rounded px-4 py-1 -mt-1 hover:bg-yellow-400 text-gray-900 lg:text-sm  md:text-xs font-semibold uppercase"+`${(select === 'order')?'  bg-[#FFDF58] text-gray-900 ':' bg-white'}`}>
                  Menu
                </p>
              </li>
              </Link>
                    {/* /************** aboutus ************/}
                    <Link to={'aboutus'} className="text-white no-underline	" onClick={()=>{setNavBar(!navBar); setSelect('aboutus')}}>
                  <li class=" lg:mx-6 sm:mx-1  mr-1">
                    <p class={` 
                 
                     lg:text-sm  md:text-xs text-xs  font-semibold hover:text-yellow-400 
                     hover:underline hover:underline-offset-8 uppercase`
                      +`${(select === 'aboutus')?' text-yellow-400 underline underline-offset-8':''}`
                     }>
                      about us
                    </p>
                  </li>
                  </Link>
                        {/* /************** contactus ************/}
                        <Link to={'contactus'} className="text-white no-underline	" onClick={()=>{setNavBar(!navBar); setSelect('contactus')}}>
                  <li class=" lg:mx-6 sm:mx-1  mr-1">
                    <p class={` 
                 
                     lg:text-sm  md:text-xs text-xs  font-semibold hover:text-yellow-400 
                     hover:underline hover:underline-offset-8 uppercase`
                      +`${(select === 'contactus')?' text-yellow-400 underline underline-offset-8':''}`
                     }>
                      contact us
                    </p>
                  </li>
                  </Link>
              <a href={Instagram_Var} target='_blank' className="text-white mt-2" rel="noreferrer">
              <li class=" md:mx-3 md:mr-5 flex  items-center justify-center mr-2">
                <p class=" lg:text-md text-sm  font-semibold md:-mt-1  uppercase">
                  <svg
                    viewBox="0 0 512.00096 512.00096"
                    className="fill-current text-white-500 hover:text-yellow-400 h-4  w-auto"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m373.40625 0h-234.8125c-76.421875 0-138.59375 62.171875-138.59375 138.59375v234.816406c0 76.417969 62.171875 138.589844 138.59375 138.589844h234.816406c76.417969 0 138.589844-62.171875 138.589844-138.589844v-234.816406c0-76.421875-62.171875-138.59375-138.59375-138.59375zm108.578125 373.410156c0 59.867188-48.707031 108.574219-108.578125 108.574219h-234.8125c-59.871094 0-108.578125-48.707031-108.578125-108.574219v-234.816406c0-59.871094 48.707031-108.578125 108.578125-108.578125h234.816406c59.867188 0 108.574219 48.707031 108.574219 108.578125zm0 0" />
                    <path d="m256 116.003906c-77.195312 0-139.996094 62.800782-139.996094 139.996094s62.800782 139.996094 139.996094 139.996094 139.996094-62.800782 139.996094-139.996094-62.800782-139.996094-139.996094-139.996094zm0 249.976563c-60.640625 0-109.980469-49.335938-109.980469-109.980469 0-60.640625 49.339844-109.980469 109.980469-109.980469 60.644531 0 109.980469 49.339844 109.980469 109.980469 0 60.644531-49.335938 109.980469-109.980469 109.980469zm0 0" />
                    <path d="m399.34375 66.285156c-22.8125 0-41.367188 18.558594-41.367188 41.367188 0 22.8125 18.554688 41.371094 41.367188 41.371094s41.371094-18.558594 41.371094-41.371094-18.558594-41.367188-41.371094-41.367188zm0 52.71875c-6.257812 0-11.351562-5.09375-11.351562-11.351562 0-6.261719 5.09375-11.351563 11.351562-11.351563 6.261719 0 11.355469 5.089844 11.355469 11.351563 0 6.257812-5.09375 11.351562-11.355469 11.351562zm0 0" />
                  </svg>
                </p>
              </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
