import React from 'react'

function CopyrightFooter() {
  return (
    <>
    <div class="text-center bg-[#4D535F] text-xs text-white">
  <div class="text-center    p-3">
  Copyright © 2022 Woffle. All Rights Reserved
    {/* <a class="text-whitehite text-white" href="https://www.google.com"> Tailwind Elements</a> */}
  </div>
</div>
    </>
  )
}

export default CopyrightFooter