import React from 'react'
import MainPageBurger from '../components/main-pages/MainPageBurger'
import MainPageDesk from '../components/main-pages/MainPageDesk'
import {Helmet} from "react-helmet";

function MainPage() {
  return (
    <>
    <div className=" ">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Burger | Shakes | Waffles</title>
                <meta name="description" content="Freshly cooked premium smashed burgers and chicken burgers. Available for collection and delivery." />
                <link rel="canonical" href="https://woffleonline.com/" />
            </Helmet>
      
    <MainPageDesk/>
      <MainPageBurger/>
        {/* <ProductRow/>  */}
    </div>
  </>
  )
}

export default MainPage